<template>
  <div class="filters-wrp">
    <form
      @submit="onSubmitVisitsForm"
      class="filter-item"
      id="kt_subheader_visits_form"
    >
      <div>
        <input
          v-model.trim="visits"
          type="number"
          class="form-control"
          placeholder="Visits"
          @input="onChangeVisits"
          id="visits"
        />
      </div>
    </form>

    <form
      @submit="onSubmitSearchForm"
      class="filter-item"
      id="kt_subheader_search_form"
    >
      <div>
        <div class="input-icon">
          <input
            v-model.trim="search"
            type="text"
            class="form-control"
            placeholder="Search..."
            @input="onChangeSearch"
            id="generalSearch"
          />
          <span class="svg-icon svg-icon-lg">
            <inline-svg
              src="public-media/svg/icons/General/Search.svg"
            ></inline-svg>
          </span>
        </div>
      </div>
    </form>
    <div class="redirect-select filter-item">
      <v-select
        :options="filterOptions"
        :clearable="true"
        placeholder="Select list"
        :getOptionLabel="(option) => option.label"
        :reduce="(option) => `${option.value}`"
        @input="(value) => setSelectedFilter('is_whitelisted', value)"
        :value="filter_selected"
        :searchable="false"
      />
    </div>

    <div class="redirect-select filter-item">
      <v-select
        :options="enabledOptions"
        :clearable="true"
        placeholder="Filter by enabled"
        :getOptionLabel="(option) => option.label"
        :reduce="(option) => `${option.value}`"
        @input="(value) => setSelectedFilter('enabled', value)"
        :value="enabled_selected"
        :searchable="false"
      />
    </div>
    <div class="redirect-select filter-item">
      <v-select
        :options="domain_type_options"
        :clearable="true"
        placeholder="Domain type"
        :getOptionLabel="(option) => option.label"
        :reduce="(option) => `${option.value}`"
        @input="(value) => setSelectedFilter('domain_type', value)"
        :value="domain_type"
        :searchable="false"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import * as types from "@/core/services/store/modules/page/types";
import vSelect from "vue-select";
import { debounce } from "@/core/services/services";

export default {
  name: "DomainsFilter",
  components: { vSelect },
  data: () => ({
    filter_selected: "",
    enabled_selected: "",
    domain_type: "",
    search: "",
    visits: "",
    domain_type_options: [
      { label: "Lander", value: "l" },
      { label: "Offer", value: "o" },
    ],
  }),
  computed: {
    ...mapState({
      search_field: (state) =>
        state.page.filter ? state.page.filter.domain : "",
      is_whitelisted: (state) =>
        state.page.filter ? state.page.filter.is_whitelisted : "",
      enabled: (state) => (state.page.filter ? state.page.filter.enabled : ""),
      filter: (state) => (state.page.filter ? state.page.filter : ""),
      domainType: (state) =>
        state.page.filter ? state.page.filter.domain_type : "",
    }),
    filterOptions: () => [
      { label: "Whitelist", value: "true" },
      { label: "Blacklist", value: "false" },
    ],
    enabledOptions: () => [
      { label: "Enabled", value: "true" },
      { label: "Disabled", value: "false" },
    ],
  },
  methods: {
    ...mapMutations({
      changeFilter: `page/${types.CHANGE_FILTER}`,
    }),
    setSelectedFilter: function (field, newValue) {
      if (newValue === null) {
        newValue = "";
      }
      this.changeFilter({ [field]: newValue });
    },
    onChangeSearch: function () {
      this.onWaitChange();
    },
    onSubmitSearchForm: function (e) {
      e.preventDefault();
      this.changeFilter({ domain: this.search });
    },
    onWaitChange: function () {
      if (this.search.length >= 2 || this.search === "") {
        this.changeFilter({ domain: this.search });
      }
    },
    onChangeVisits: function () {
      this.onWaitChangeVisits();
    },
    onSubmitVisitsForm: function (e) {
      e.preventDefault();
      this.changeFilter({ visits: this.visits });
    },
    onWaitChangeVisits: function () {
      this.changeFilter({ visits: this.visits });
    },
  },
  watch: {
    search_field(newValue) {
      this.search = newValue;
    },
    is_whitelisted(newValue) {
      this.filter_selected = newValue;
    },
    enabled(newValue) {
      this.enabled_selected = newValue;
    },
    domainType(newValue) {
      this.domain_type = newValue;
    },
  },
  created: function () {
    this.onWaitChange = debounce(this.onWaitChange, 500);
    this.onWaitChangeVisits = debounce(this.onWaitChangeVisits, 500);
    this.filter_selected = this.is_whitelisted;
    this.domain_type = this.domainType;
    this.search = this.search_field;
  },
};
</script>

<style scoped lang="scss">
.filters-wrp {
  display: flex;
  gap: 4px;
  justify-content: flex-start;
  flex-wrap: wrap;
}
@media (max-width: 553px) {
  .filters-wrp {
    flex-direction: column;
    .filter-item {
      width: 100%;
    }
  }
}
</style>

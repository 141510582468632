<template>
  <div>
    <div class="row">
      <div class="col-xxl-12">
        <DomainsTable
          :list="list"
          :count="count"
          :status="status"
          :title="`<span class='card-label font-weight-bolder text-dark'> Domains</span>
              <span class='text-muted mt-3 font-weight-bold font-size-sm'>
                  Total count: ${count}
              </span>`"
        >
          <domains-filter />
        </DomainsTable>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

import { requestStatus, buildFilterString } from "@/core/services/services";
import * as TYPES from "@/core/services/store/modules/domains/types";
import * as PAGE_TYPES from "@/core/services/store/modules/page/types";

import DomainsTable from "./components/DomainsTable";
import DomainsFilter from "./components/DomainsFilter";

export default {
  name: "Domains",
  components: {
    DomainsTable,
    DomainsFilter,
  },
  computed: {
    ...mapState({
      list: (state) => state.domains.list,
      count: (state) => state.domains.count,
      status: (state) => state.domains.status,
      filter: (state) => state.page.filter,
    }),
  },
  methods: {
    ...mapActions({
      loadDataAction: `domains/${TYPES.GET_DOMAINS}`,
    }),
    ...mapMutations({
      setFilter: `page/${PAGE_TYPES.SET_PAGE}`,
      changeFilter: `page/${PAGE_TYPES.CHANGE_FILTER}`,
    }),

    loadData: function (filter) {
      this.loadDataAction(buildFilterString(filter));
    },
  },
  watch: {
    status(newValue) {
      if (
        (newValue.change_field === "edit" &&
          newValue.edit === requestStatus.success) ||
        (newValue.change_field === "bulk_edit" &&
          newValue.bulk_edit === requestStatus.success)
      ) {
        this.loadData(this.filter);
      }
    },
    filter(newValue) {
      this.loadData(newValue);
    },
  },
  created: function () {
    this.loadData(this.filter);
  },
};
</script>
